import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DiseasesConfirmationDialog from 'components/DiseasesConfirmationDialog';
import ScrollToFirstError from 'components/ScrollToFirstError';
import { wizardFormContext } from 'components/WizardForm/context';
import { WizardStepProps } from 'components/WizardStep';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormState, HealthDeclaration, SimpleAnswer } from 'types';

import { WizardFormStep } from 'common/enums';
import { useModal, useSaveHealthDeclaration } from 'common/hooks';
import ReferencesProvider from 'common/providers/ReferencesProvider';
import { transformValueFromNumberToString, transformValueFromStringToNumber } from 'common/utils/fieldLevelValidators';
import { isBasicHealthDeclarationFilled } from 'common/utils/generalInformation';

type WizardFormProps = {
  children: NonNullable<
    React.ReactElement<WizardStepProps> | Array<React.ReactElement<WizardStepProps>>
  >;
  initialValues: HealthDeclaration;
};

const WizardForm = ({ children, initialValues }: WizardFormProps) => {
  const { save, loading } = useSaveHealthDeclaration();
  const [activeStep, setActiveStep] = useState<WizardFormStep>(() => {
    const { isInstructionsRead, formState, hasMedicalIssues } = initialValues;
    if (!isInstructionsRead) {
      return WizardFormStep.Instructions;
    }
    if (formState === FormState.Questionnaire || formState === FormState.Completed) {
      return WizardFormStep.Questionnaire;
    }
    if (
      formState === FormState.Detailed ||
      (isBasicHealthDeclarationFilled(initialValues) && hasMedicalIssues === SimpleAnswer.No)
    ) {
      return WizardFormStep.Diseases;
    }
    return WizardFormStep.GeneralInformation;
  });

  const [snapshot, setSnapshot] = useState<HealthDeclaration>({ ...initialValues, weight: initialValues?.weight && transformValueFromNumberToString(initialValues.weight) });
  const { open, openModal, closeModal } = useModal();
  const { Provider } = wizardFormContext;

  const shouldConfirmBeforeSubmit = !open && activeStep === WizardFormStep.Diseases;

  const steps = React.Children.toArray(children);
  const step = steps[activeStep] as React.ReactElement;
  const isLastStep = activeStep === WizardFormStep.Complete;

  const next = (values: HealthDeclaration) => {
    setSnapshot({...values,  weight: values?.weight && transformValueFromNumberToString(values.weight)});
    setActiveStep((prevStepNumber) => Math.min(prevStepNumber + 1, WizardFormStep.Questionnaire));
  };

  const previous = (values: HealthDeclaration) => {
    setSnapshot(values);
    setActiveStep((prevStepNumber) => Math.max(prevStepNumber - 1, WizardFormStep.Instructions));
  };

  const handleSubmit = async (
    values: HealthDeclaration,
    formikBag: FormikHelpers<HealthDeclaration>
  ) => {

    if (values.weight) {
      values.weight = transformValueFromStringToNumber(String(values.weight));
    }
    if (shouldConfirmBeforeSubmit && values.diseasesIds.length > 0) {
      openModal();
      return;
    }

    if (activeStep === WizardFormStep.GeneralInformation) {
      values.formState = FormState.Detailed;
    }

    if (step.props.waitForResponse || values.formState === FormState.Completed) {
      await save(values);
    } else {
      save(values);
    }
    if (!isLastStep) {
      formikBag.setTouched({});
      next(values);
    }
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <ReferencesProvider shouldRequest={formik.values.hasMedicalIssues === SimpleAnswer.Yes}>
          <Provider value={(values) => previous(values ?? formik.values)}>
            <ScrollToFirstError />
            <Box sx={{ paddingBottom: (theme) => theme.spacing(15) }} data-testid="health-declaration-component">
              <Form autoComplete="off">
                <DiseasesConfirmationDialog
                  maxWidth="sm"
                  open={open}
                  loading={loading}
                  onClose={closeModal}
                  onConfirm={save}
                />
                {step}
              </Form>
            </Box>
          </Provider>
        </ReferencesProvider>
      )}
    </Formik>
  );
};

export default WizardForm;
